import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
//import useLiveControl from "../../controlled/useLiveControl"

const HvacFinancingPage = () => {
  //const { isStaging } = useLiveControl()
  return (
    <Layout>
      <Seo
        title={"HVAC Customer Financing For Contractors"}
        description={
          "HVAC contractors who offer financing have a real advantage. Learn how your business can thrive in these four areas."
        }
      />
      <Helmet>
        <body className="page-hvac-financing industry" />
        <script type="application/ld+json" async={true}>
          {`{
 "@context": "https://schema.org/",
 "@type": "Product",
 "name": "HVAC Customer Financing For Contractors",
 "image": "",
 "description": "By offering financing, you are providing a convenient and flexible payment option to your customers for their HVAC system upgrades and replacements."
 }`}
        </script>
      </Helmet>

      <section
        className="banner-area editable-page-hero-banner"
        id="hvac-page-banner-section"
        style={{
          backgroundImage:
            "url(" +
            "http://gbdevpress.enerbank.com/wp-content/uploads/2022/03/hvac-hero.jpg" +
            ")",
        }}
      >
        <div className="row align-items-center relative banner-area-text-container">
          <div className="container text-center">
            <h1 className="text-white" style={{ padding: "80 0" }}>
              HVAC Customer Financing For Contractors
            </h1>
          </div>
        </div>
      </section>
      <section className="paragraph-text-block">
        <div className="container">
          <div className="justify-content-center">
            <p>
              As an experienced HVAC contractor, you're probably aware that most
              homeowners aren't prepared to pay for the unexpected cost that an
              HVAC system in need of repair can bring. In fact, studies have
              shown that a third of homeowners don't have a planned way to pay
              for a project before speaking to a contractor. As an HVAC
              contractor, you can solve such problems by offering{" "}
              <Link
                className="eb-link"
                to="/contractor-financing/what-is-contractor-financing/"
              >
                financing to clients
              </Link>{" "}
              as part of your service. As an experienced HVAC contractor,
              you&#39;re probably aware that most homeowners aren&#39;t prepared
              to pay for the unexpected cost that an HVAC system in need of
              repair can bring. In fact, studies have shown that a third of
              homeowners don&#39;t have a planned way to pay for a project
              before speaking to a contractor. As an HVAC contractor, you can
              solve such problems by offering financing as part of your service.
            </p>
          </div>
        </div>
      </section>
      <div class="benefits-head">
        <h2 className="text-center bg-blue">
          How Does HVAC Financing for Contractors Benefit You?
        </h2>
      </div>
      <section className="financing-benefits">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <p>
                By offering financing, you are providing a convenient and
                flexible payment option to your customers for their HVAC system
                upgrades and replacements. Of course, offering financing has
                benefits for you too:
              </p>
            </div>
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/05/close-deals-icon.png"
                    class=""
                    alt="document deadline icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Close Deals Quickly</h3>
                  <p class="card-text">
                    Time is of the essence when it comes to an HVAC project.
                    With EnerBank, your customer can receive a lending decision
                    within minutes and only need to sign once upfront. This
                    means you can get to work sooner and finish jobs faster.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/03/fast-funding-icon.png"
                    class=""
                    alt="flying money icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Fast Funding</h3>
                  <p class="">
                    We&#39;ve made requesting funds from homeowners quick and
                    easy. At the time of application, your customer will select
                    whether they want to be contacted via text or phone call.
                    When you&#39;re ready to get started on the project, simply
                    send the request, and the funds will typically hit your
                    account within 24 hours of authorization.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/03/customer-service-icon.png"
                    class=""
                    alt="champion icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Better Customer Experience</h3>
                  <p class="">
                    Your customers will love the ease and convenience that comes
                    along with EnerBank payment options including; a choice of
                    paperless application methods and express loan decision. A
                    happy customer is more likely to recommend you to their
                    friends and family, helping you to further grow your
                    business.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div class="card">
                <div class="card-image">
                  <img
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2022/03/competative-edge-icon.png"
                    class=""
                    alt="run icon"
                  />
                </div>
                <div class="card-body">
                  <h3>Edge Out the Competition</h3>
                  <p class="">
                    Looking for a way to stand out from the competition?
                    Offering reliable financing as a part of your services can
                    help increase both the visibility and credibility of your
                    business. Research has shown that by offering payment
                    options at three key touchpoints; in your marketing, during
                    the initial call, and while in the home, you can increase
                    your close rate by up to 50%<sup>*</sup>.<br />
                    <small>
                    <sup>*</sup>Based on the Brickyard Study conducted by EnerBank USA.
                      2018.
                    </small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12">
              <p>
                Plus, as a part of an EnerBank payment options program, you can
                easily track loans with our PartnerPortal system, have access to
                high-quality marketing materials, and you&#39;ll be assigned
                your very own relationship manager who can assist you with your
                business. Ready to get started?{" "}
                <a className="eb-link" href="/sign-up/">
                  Click here
                </a>{" "}
                to tell us about yourself and a new contractor consultant will
                reach out to you.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="offer-financing">
        <div className="container">
          <div className="row pb-5">
            <div className="col-12">
              <h2 className="text-center">
                How do HVAC Contractors Offer Financing?
              </h2>
              <p>
              Research shows that over 55% of home improvement projects are financed in some way.<sup>*</sup> By joining one of
                our HVAC financing programs, you can start offering fast and
                easy financing options to your customers. When they see you as
                their problem solver, you&#39;re more likely to get even more of
                their business.
              </p>
              <p>
                Our financing programs include three paperless application
                methods, including the Mobile Loan App, our Online Application,
                or Loan-by-Phone. With quick approvals, you can get everything
                signed, sealed, and delivered before you leave the home.
              </p>
              <p>
                <small>
                  <sup>*</sup>Based on the HIRI TFG Monthly report, released October 2023.
                </small>
              </p>
            </div>
          </div>
          <div className="row justify-content-center pb-5">
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    class=""
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/06/sac-icon.png"
                    alt="same-as-cahs loans"
                  />
                </div>
                <div class="card-body">
                  <h3>Same-As-Cash-Loans</h3>
                  <p class="card-text">
                    The Same-As-Cash Loan (SAC) from EnerBank makes closing
                    deals, increasing project size, and delighting your
                    customers an absolute breeze. We call it “Same-As-Cash,” but
                    really, it&#39;’s better than cash, because i&#39;s a
                    short-term lending solution with no interest and no monthly
                    payment required during the “same-as-cash” period — (on
                    approved credit – interest waived if paid in full during the
                    same-as-cash period) — which means your customer can use
                    their cash elsewhere.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    class=""
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/06/reduced-interest-loans-icon.png"
                    alt="reduced interest loans"
                  />
                </div>
                <div class="card-body">
                  <h3>Reduced Interest Loans</h3>
                  <p class="card-text">
                    The Reduced Interest Loan (RIL) is half of your payment
                    options one-two punch (the other is the Same-As-Cash Loan).
                    These two choices should always be offered together, like
                    peanut butter and jelly. A RIL is just like it sounds — a
                    lower interest rate loan that is set up for a longer-term.
                    Your customer pays back the RIL through easy monthly
                    payments, helping them get the home improvement project they
                    want in an affordable way.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    class=""
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/06/zero-interest-icon.png"
                    alt="zero interest loans"
                  />
                </div>
                <div class="card-body">
                  <h3>Zero Interest Loans</h3>
                  <p class="card-text">
                    Zero interest is a mighty attractive offer. The EnerBank
                    Zero Interest Loan (ZIL) offers the unique features of equal
                    monthly payments combined with a 0% fixed APR. If qualified
                    for a ZIL through EnerBank, your customers will appreciate
                    getting the home improvements they want with a 0% fixed APR
                    throughout the full term of their loan.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    class=""
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/06/traditional-installment-icon.png"
                    alt="traditional installment loans"
                  />
                </div>
                <div class="card-body">
                  <h3>Traditional Installment Loans</h3>
                  <p class="card-text">
                    The most basic plank in your payment options toolbox is the
                    Traditional Installment Loan (TIL). This is a longer-term
                    loan with customers paying it off through equal monthly
                    installments. A TIL can be a good choice for a customer who
                    wants to make payments and get a better interest rate than a
                    credit card.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <div className="card-image">
                  <img
                    class=""
                    src="http://gbdevpress.enerbank.com/wp-content/uploads/2023/06/yes-loan-icon.png"
                    alt="yes loans"
                  />
                </div>
                <div class="card-body">
                  <h3>Yes Loans</h3>
                  <p class="card-text">
                    Every customer you work with is unique, and so is their
                    financial situation. That’s why EnerBank developed the YES
                    Loan, an integrated counter-offer payment option that can
                    help your less creditworthy customers get funding for their
                    home improvement projects.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="faqs" className="contractor-faqs">
        <div className="">
          <div className="container">
            <h2 className="text-center pb-0">Frequently Asked Questions</h2>
          </div>
        </div>
        <div className="container pt-60">
          <div className="">
            <dl>
              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    How Long Does it Take for an HVAC Customer to Get Approved
                    for Financing?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    It can depend on both the lender you work with and the
                    application method of your customer. In many cases, when
                    using digital application methods, you can typically expect
                    a quick credit decision, sometimes in just a few minutes.
                    Missing or incorrect information included in the application
                    may also delay the process. EnerBank’s pre-approval process
                    can help by giving your customers a better idea of what they
                    may qualify for, this can speed up the loan process, helping
                    you close more deals.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    How Can I Offer My Customers Financing for HVAC?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    As an extension of your brand, it&#39;’s important you sign
                    up with a reliable lender that&#39;s going to provide a
                    great experience to your customers. Once you select a
                    lender, we recommend mentioning the availability of
                    financing at these three touchpoints: in your marketing
                    materials, when setting the appointment, and during the bid
                    in the customer&#39;s home. Every homeowner should be
                    offered financing to get the most out of your program. The
                    more of routine that you make offering contractor financing,
                    the easier it will be.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    What HVAC Services or Products Can Be Financed?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    It can depend on the lender that you&#39;re using for your
                    contractor financing. During your research phase, make sure
                    that any work you do is a qualified project. In most cases,
                    you can expect the HVAC systems and installation process to
                    be covered by financing. Any maintenance costs that arise
                    from the system, such as an annual tune up, are usually not
                    covered by financing.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    How Can an HVAC Contractor Offer Financing to their
                    Customers?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    Once you&#39;ve selected a lender and have completed the
                    sign-up process, you&#39;ll gain access to the tools and
                    resources you need to begin offering contractor financing.
                    Some lenders, like EnerBank, provide you and every member of
                    your team with training so you know the most effective ways
                    to implement financing into your business practices. Make it
                    a part of your regular sales process to offer financing to
                    every customer to get the most out of your program.
                    Don&#39;t forget to mention the availability of financing in
                    your marketing materials and website.
                  </span>
                </dd>
              </div>

              <div
                itemscope
                itemprop="mainEntity"
                itemtype="https://schema.org/Question"
              >
                <dt className="wcf-faqs-dt d-flex align-items-start mb-1">
                  <span>
                    <span className="wcf-faqs-dl-point">Q</span>
                  </span>
                  <span itemprop="name">
                    Is it Better to Lease or Finance an HVAC System?
                  </span>
                </dt>
                <dd
                  itemscope
                  itemprop="acceptedAnswer"
                  itemtype="https://schema.org/Answer"
                  className="wcf-faqs-dt d-flex flex-row align-items-start mb-4"
                >
                  <span>
                    <span class="wcf-faqs-dl-point">A</span>
                  </span>
                  <span itemprop="text">
                    It depends on the individualn&#39;s situation. Upfront,
                    leasing is the less expensive option, but in the long run,
                    they may end up paying more than they would have if they had
                    purchased the unit. Contractor financing provides an easy
                    way for your customers to fund their HVAC project,
                    especially when they donn&#39;t have the cash on hand to pay
                    for it out of pocket.
                  </span>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
      <section className="start-financing pt-0">
        <div className="bg-blue py-4">
          <div className="container">
            <h2 className="text-center text-white pb-0">
              Start Offering HVAC Customer Financing!
            </h2>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12 pt-60">
              <p>
                It&#39;s essential you work with a reliable lending partner to
                offer the right payment options for your customers. EnerBank has
                been offering HVAC financing for contractors for two decades —
                we have a variety of loan products designed specifically for
                contractors and their clients.
              </p>
              <p className="text-center">
                <a
                  className="btn btn-primary"
                  style={{
                    marginTop: "2rem",
                    padding: "0.75rem",
                  }}
                  href="/sign-up/"
                >
                  Get Started Now
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default HvacFinancingPage
